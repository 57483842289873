import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from './Login';
import ForgotPasswordPage from './ForgotPassword';
import LoginInitialPage from './LoginInitialPage';
import DailyReport from './DailyReport';
import Summury from './Summary';
import MasterEmployees from './MasterEmployees';
import Reports from './Reports';

function RoutesPage() {
    const accessToken = localStorage.getItem('accessToken');

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginInitialPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/ForgotPassword" element={<ForgotPasswordPage />} />
                {accessToken ? (
                    <>
                        <Route path="/summary" element={<Summury />} />
                        <Route path="/masterEmployees" element={<MasterEmployees />} />
                        <Route path="/dailyreport" element={<DailyReport />} />
                        <Route path="/report" element={<Reports/> } />

                    </>
                ) : (
                    <Route path="/*" element={<Login />} />
                )}
            </Routes>
        </BrowserRouter>
    );
}

export default RoutesPage;
