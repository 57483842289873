import React, { useEffect, useState } from "react";
import axios from "axios";
import HeaderPage from "./HeaderPage";
import SidebarPage from "./SidebarPage";
import { Form, Button, Table } from "react-bootstrap";
import "../css/DailyReport.css";
import URL from "../Pages/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { differenceInMinutes, format } from 'date-fns';


function DailyReport() {
  const regex = /^[a-zA-Z0-9]*$/;

  const [empname, setempname] = useState("");
  const [empcode, setempcode] = useState("");

  const itemsPerPage = 8;
  const MasterEmployeeItemPerpage = 7;
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMasterEmployeePage, setMasterEmployeePage] = useState(1);
  const [masterEmployees, setMasterEmployees] = useState([]);
  const [presentCount, setpresentCount] = useState(0);
  const [absentCount, setabsentCount] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [ActiveEmployees, setActiveEmployee] = useState([]);

  function splitAndFormatDate(timestamp) {
    let [date, time] = timestamp.split("T");
    let [hour, minute] = time.split(":").map(Number);
    let ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${date}`;
  }

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);      
    const ampm = hours >= 12 || hours == 0 ? "PM" : "AM";
    const formattedHour = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");  
    return `${
      String(formattedHour).length == 1 ? "0" + formattedHour : formattedHour
  }:${String(formattedMinutes).length == 1 ? "0" + formattedMinutes : formattedMinutes} ${ampm}`;  }

  function convertDecimalToTime(decimal) {
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);    
    return  hours == '0' && minutes == '00' ? '0 Hrs' : `${hours > 4 ? hours - 1 : hours} Hrs ${minutes.toString().padStart(2, '0')} Mins`;
  }


  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const fetchData = async () => {
      try {
        if (!accessToken) {
          throw new Error("Access token not found");
        }
        const headers = { Authorization: `Bearer ${accessToken}` };

        const response = await axios.get(`${URL}/api/getEmployees`, {
          headers,
        });
        const { Presented_data, Absent_data, Absentcount, Presentedcount } =
          response.data;
        setFilteredData(Presented_data);
        setActiveEmployee(Presented_data);
        setMasterEmployees(Absent_data);
        setpresentCount(Presentedcount);
        setabsentCount(Absentcount);
        setCurrentPage(1);
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "empname":
        setempname(value);
        break;
      case "empcode":
        const regex = /^[a-zA-Z0-9]+$/;
        const isValid = regex.test(value);
        setempcode(isValid ? value : "");
        break;
      default:
        break;
    }

    let filteredResult = ActiveEmployees;

    if (value.trim() !== "") {
      if (name === "empname") {
        filteredResult = filteredResult.filter((item) =>
          item.EmployeeName.toLowerCase().includes(value.toLowerCase())
        );
      } else if (name === "empcode") {
        filteredResult = filteredResult.filter(
          (item) => item.EmployeeCode.toLowerCase() === value.toLowerCase()
        );
      }
    }

    setFilteredData(filteredResult);
    setCurrentPage(1);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const MasterEmployeepaginatedData = masterEmployees.slice(
    (currentMasterEmployeePage - 1) * MasterEmployeeItemPerpage,
    currentMasterEmployeePage * MasterEmployeeItemPerpage
  );

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filteredData.length / itemsPerPage))
    );
  };

  const handleMasterEmployeePrevPage = () => {
    setMasterEmployeePage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleMasterEmployeeNextPage = () => {
    setMasterEmployeePage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(masterEmployees.length / MasterEmployeeItemPerpage)
      )
    );
  };

  return (
    <div>
      <div className="Header">
        <HeaderPage />
      </div>
      <div className="mainBody d-flex">
        <div className="leftSidebar" style={{ width: "20%" }}>
          <SidebarPage />
        </div>
        <div
          className="DailyReport mt-4 ms-3"
          style={{
            width: "80%",
            padding: "16px",
            position: "relative",
            top: "72.8px",
          }}
        >
          {isLoading ? (
            <div className="payment_overlay">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ position: "relative", top: "12%", left: "12%" }}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fa-spin"
                  style={{ fontSize: "40px", color: "#FFF" }}
                />
                <p className="mt-2 payment_loading">Please wait...</p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="reportFilterbar">
            <Form className="FilterData">
              <div className="d-flex justify-content-between">
                <div className="d-flex" style={{ width: "83%" }}>
                  <Form.Group
                    controlId="EmployeeName"
                    id="formGroupEmployeeName"
                  >
                    <Form.Label
                      className="d-flex text-start"
                      id="reportFormLabel"
                    >
                      {" "}
                      Employee Name{" "}
                    </Form.Label>
                    <div className="EmployeeName d-flex pb-3">
                      <Form.Control
                        id="EmployeeName"
                        type="text"
                        name="empname"
                        placeholder="Enter Name"
                        value={empname}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="EmployeeCode"
                    id="formGroupEmployeeCode"
                  >
                    <Form.Label
                      className="d-flex  text-start"
                      id="reportFormLabel"
                    >
                      {" "}
                      Employee Code{" "}
                    </Form.Label>
                    <div className="EmployeeCode d-flex pb-3">
                      <Form.Control
                        id="EmployeeCode"
                        type="text"
                        name="empcode"
                        placeholder="Enter Code"
                        maxLength={7}
                        value={empcode}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
          <hr />
          <p className="myheading">
            Presented Employees <b>{presentCount}</b>
          </p>
          <div
            className="ReportResults"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <Table responsive="sm" id="TableResults">
              <thead className="thead-dark">
                <tr>
                  <th className="px-4 py-4">Employee Code</th>
                  <th className="px-4 py-4">Employee Name</th>
                  <th className="px-4 py-4">Log Date</th>
                  <th className="px-4 py-4">Entry Time</th>
                  <th className="px-4 py-4">Exit Time</th>
                  <th>
                    Productive <br /> Working Hours
                  </th>
                </tr>
              </thead>
              <tbody>
              {paginatedData.map((item, index) => {      
                return (
                  <tr key={index}>
                    <td className="px-4">{item.EmployeeCode}</td>
                    <td>{item.EmployeeName}</td>
                    <td>{splitAndFormatDate(item.FirstLogDate)}</td>
                    <td>{formatTime(item.FirstLogTime)}</td>
                    <td>{formatTime(item.FirstLogTime) === formatTime(item.LastLogTime) ? "-" : formatTime(item.LastLogTime)}</td>
                    <td>{convertDecimalToTime(item.TimeDifferenceMinutes)}</td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <Button
              variant="primary"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            <div>
              Page {currentPage} of{" "}
              {Math.ceil(filteredData.length / itemsPerPage)}
            </div>
            <Button
              variant="primary"
              onClick={handleNextPage}
              disabled={
                currentPage === Math.ceil(filteredData.length / itemsPerPage)
              }
            >
              Next
            </Button>
          </div>
          <hr />
          <p className="myheading">
            Absent Employees <b>{absentCount}</b>
          </p>
          <div
            className="AbsentReportResults"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <Table responsive="sm" id="AbsentTableResults">
              <thead className="thead-dark">
                <tr>
                  <th className="px-4" style={{ textAlign: "start" }}>
                    Employee Name
                  </th>
                  <th>Employee Code</th>
                </tr>
              </thead>
              {MasterEmployeepaginatedData.map((item) => (
                <tbody className="">
                  <tr style={{ borderBottom: "1px solid #DADADA" }}>
                    <td className="px-4" style={{ textAlign: "start" }}>
                      {item.EmployeeName}
                    </td>
                    <td>{item.EmployeeId}</td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <Button
              variant="primary"
              onClick={handleMasterEmployeePrevPage}
              disabled={currentMasterEmployeePage === 1}
            >
              Prev
            </Button>
            <div>
              Page {currentMasterEmployeePage} of{" "}
              {Math.ceil(masterEmployees.length / MasterEmployeeItemPerpage)}
            </div>
            <Button
              variant="primary"
              onClick={handleMasterEmployeeNextPage}
              disabled={
                currentMasterEmployeePage ===
                Math.ceil(masterEmployees.length / MasterEmployeeItemPerpage)
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyReport;
