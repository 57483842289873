import React, { useEffect, useState } from "react";
import HeaderPage from "./HeaderPage";
import SidebarPage from "./SidebarPage";
import { Button, Table } from "react-bootstrap";
import "../css/Summary.css";
import axios from "axios";
import URL from "../Pages/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function MasterEmployees() {
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Count, setCount]=useState(0);
  const [isLoading, setisLoading] = useState(true);

  const itemsPerPage = 10;

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    const fetchData = async () => {
      try {
        if (!accessToken) {
          throw new Error('Access token not found');
        }
        const headers = { Authorization: `Bearer ${accessToken}` };
        const response = await axios.get(`${URL}/api/getAllEmployees`, { headers });
        setFilteredData(response.data.data);
        setCount(response.data.count);
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div>
      <div className="Header">
        <HeaderPage />
      </div>
      <div className="mainBody d-flex">
        <div className="leftSidebar" style={{ width: "20%" }}>
          <SidebarPage />
        </div>
        <div
          className="DailyReport mt-4 ms-3"
          style={{width:'80%', padding: '16px', position: 'relative', top: '66px'}}
        >
        {isLoading ? (
            <div className="payment_overlay">
              <div className="d-flex align-items-center justify-content-center flex-column" style={{ position: 'relative', top: '12%', left: '12%'}}>
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" style={{ fontSize: '40px', color: '#FFF' }} />
                  <p className='mt-2 payment_loading' >Please wait...</p>
              </div>
            </div> 
          ): ("" )}
          <hr />
          <p className="myheading">All Employees <b>{Count}</b></p>

          <div className="ReportResults">
            <Table responsive="sm" id="TableResults">
              <thead className="thead-dark">
                <tr>
                  <th>Employee Name</th>
                  <th>Employee Code</th>
                  <th className="px-4" style={{textAlign:"start"}}>Email ID</th>
                </tr>
              </thead>
              {paginatedData.map((item) => (
                <tbody className="">
                  <tr style={{ borderBottom: "1px solid #DADADA" }}>
                    <td className="px-4" style={{textAlign:"start"}}>{item.EmployeeName}</td>
                    <td>{item.EmployeeId}</td>
                    <td className="px-4" style={{textAlign:"start"}}>{item.Email}</td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <Button
              variant="primary"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            <div>
              Page {currentPage} of {totalPages}
            </div>
            <Button
              variant="primary"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterEmployees;
