import {NotificationManager } from "react-notifications";
import 'react-notifications/lib/notifications.css';


export const ActionOpenNotification = (type, messages, description) => {
    if (messages === "failed") {
      NotificationManager.error(description,'', 2500);
    } else {
      NotificationManager.success(description);
    }
  };

