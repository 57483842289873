import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Button, Table, Modal, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HeaderPage from "./HeaderPage";
import SidebarPage from "./SidebarPage";
import "../css/DailyReport.css";
import URL from "../Pages/constants";

function Reports() {
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeAttendance, setEmployeeAttendance] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [fromDate, setFromDate] = useState(getCurrentDate());
  const [toDate, setToDate] = useState(getCurrentDate());
  const [empname, setEmpName] = useState("");
  const [allEmployees, setAllEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [absentDates, setAbsentDates] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);

  const currentDate = getCurrentDate();

  const fetchPublicHolidays = async () => {
    try {
      const response = await axios.get(
        "https://calendarific.com/api/v2/holidays",
        {
          params: {
            api_key: "CJsgEPEe9FVFJvIFwvnzPSyuLB7HcBNR",
            country: "IN",
            year: new Date().getFullYear(),
          },
        }
      );
      const holidays = response.data.response.holidays.map((holiday) => ({
        date: holiday.date.iso,
        name: holiday.name,
      }));
      setPublicHolidays(holidays);
    } catch (error) {
      console.error("Error fetching public holidays:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPublicHolidays();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("accessToken");
      const headers = { Authorization: `Bearer ${accessToken}` };
      const response = await axios.get(`${URL}/api/getDatewise`, { headers });
      const { data, AllEmployees } = response.data;
      setInitialData(data);
      setAllEmployees(AllEmployees);
      filterDataByDate(data, AllEmployees, fromDate, toDate);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };

  const filterDataByDate = (data, allEmployees, from, to) => {
    const filteredResult = data.filter((item) => {
      const logDate = item.LogDate.split("T")[0];
      return logDate >= from && logDate <= to;
    });

    const attendance = allEmployees.map((emp) => ({
      ...emp,
      absentDays: calculateAbsentDays(emp.EmployeeId, data, from, to),
      absentDates: getAbsentDates(emp.EmployeeId, data, from, to),
    }));

    setFilteredData(
      fromDate == currentDate
        ? filteredResult.sort(
            (a, b) => new Date(a.EntryTime) - new Date(b.EntryTime)
          )
        : filteredResult
    );
    setEmployeeAttendance(attendance);
    setCurrentPage(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fromDate") {
      setFromDate(value);
      if (value > toDate) setToDate(value);
    } else if (name === "toDate") {
      setToDate(value);
      if (value < fromDate) setFromDate(value);
    } else if (name === "empname") {
      setEmpName(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    filterData();
  };

  const filterData = () => {
    let filteredResult = initialData.filter((item) => {
      const logDate = item.LogDate.split("T")[0];
      return logDate >= fromDate && logDate <= toDate;
    });

    if (empname) {
      filteredResult = filteredResult.filter((item) =>
        item.EmployeeName.toLowerCase().includes(empname.toLowerCase())
      );
    }

    const attendance = allEmployees.map((emp) => ({
      ...emp,
      absentDays: calculateAbsentDays(
        emp.EmployeeId,
        initialData,
        fromDate,
        toDate
      ),
      absentDates: getAbsentDates(
        emp.EmployeeId,
        initialData,
        fromDate,
        toDate
      ),
    }));

    let absentEmployee = attendance;
    if (empname) {
      absentEmployee = attendance.filter((item) =>
        item.EmployeeName.toLowerCase().includes(empname.toLowerCase())
      );
    }

    const sortedFilteredResult =
      fromDate === currentDate
        ? filteredResult.sort(
            (a, b) => new Date(a.EntryTime) - new Date(b.EntryTime)
          )
        : filteredResult;

    setFilteredData(sortedFilteredResult);
    setEmployeeAttendance(absentEmployee);

    setCurrentPage(1);
  };

  const getAbsentDates = (employeeId, data, from, to) => {
    const dateRange = getDaysArray(new Date(from), new Date(to));
    const presentDays = new Set(
      data
        .filter(
          (item) =>
            item.EmployeeCode === employeeId &&
            item.LogDate.split("T")[0] >= from &&
            item.LogDate.split("T")[0] <= to
        )
        .map((item) => item.LogDate.split("T")[0])
    );
    return dateRange
      .filter((date) => {
        const dayOfWeek = date.getDay();
        return (
          dayOfWeek !== 0 && !presentDays.has(date.toISOString().split("T")[0])
        );
      })
      .map((date) => ({
        date: date.toISOString().split("T")[0],
        day: getDayName(date.getDay()),
      }));
  };

  const calculateAbsentDays = (employeeId, data, from, to) => {
    const dateRange = getDaysArray(new Date(from), new Date(to));
    const presentDays = new Set(
      data
        .filter(
          (item) =>
            item.EmployeeCode === employeeId &&
            item.LogDate.split("T")[0] >= from &&
            item.LogDate.split("T")[0] <= to
        )
        .map((item) => item.LogDate.split("T")[0])
    );
    return dateRange.filter((date) => {
      const dayOfWeek = date.getDay();
      return (
        dayOfWeek !== 0 && !presentDays.has(date.toISOString().split("T")[0])
      );
    }).length;
  };

  const getDayName = (dayIndex) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[dayIndex];
  };

  function convertDecimalToTime(decimal) {
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);
    return hours == "0" && minutes == "00"
      ? "0 Hrs"
      : `${hours > 5 ? hours - 1 : hours} Hrs ${minutes
          .toString()
          .padStart(2, "0")} Mins`;
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filteredData.length / itemsPerPage))
    );
  };

  const handleViewDates = (employee) => {
    setSelectedEmployee(employee);
    setAbsentDates(employee.absentDates);
    setShowModal(true);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getMinDate = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    return `${currentYear}-01-01`;
  };

  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    return maxDate.toISOString().split("T")[0];
  };

  function getCurrentDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  }

  var groupedDates = groupAbsentDatesByMonth(absentDates);

  const isPublicHoliday = (date) => {
    const holiday = publicHolidays.find((holiday) => holiday.date === date);
    return {
      isHoliday: holiday !== undefined,
      holidayName: holiday ? holiday.name : null,
    };
  };


  return (
    <div>
      <div className="Header">
        <HeaderPage />
      </div>
      <div className="mainBody d-flex">
        <div className="leftSidebar" style={{ width: "20%" }}>
          <SidebarPage />
        </div>
        <div className="DailyReport mt-4 ms-3" style={{ width: "80%" }}>
          {isLoading && (
            <div className="payment_overlay">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fa-spin"
                  style={{ fontSize: "40px", color: "#FFF" }}
                />
                <p className="mt-2 payment_loading">Please wait...</p>
              </div>
            </div>
          )}
          <div className="reportFilterbar">
            <Form className="FilterData" onSubmit={handleSubmit}>
              <div
                className="d-flex justify-content-around"
                style={{ marginTop: "5.5%" }}
              >
                <Form.Group controlId="EmployeName">
                  <Form.Label className="text-start">Employee Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="empname"
                    placeholder="Enter Name"
                    value={empname}
                    onChange={handleChange}
                    style={{
                      border: "block",
                      backgroundColor: "none",
                      fontWeight: "400",
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="FormFromInput">
                  <Form.Label className="text-start">From Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleChange}
                    min={getMinDate()}
                    max={getMaxDate()}
                  />
                </Form.Group>

                <Form.Group controlId="FormToInput">
                  <Form.Label className="text-start">To Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="toDate"
                    value={toDate}
                    onChange={handleChange}
                    min={getMinDate()}
                    max={getMaxDate()}
                  />
                </Form.Group>
                <div id="SButton">
                  <Button
                    type="submit"
                    className="btn btn-info"
                    style={{ color: "white" }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <hr />
          <p className="myheading">
            Present Employees {`(` + fromDate + " - " + toDate + `)`}{" "}
          </p>
          <div
            className="ReportResults"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <Table responsive="sm" id="TableResults">
              <thead className="thead-dark">
                <tr>
                  <th className="px-4 py-4">Employee Code</th>
                  <th className="px-4 py-4">Employee Name</th>
                  <th className="px-4 py-4">Log Date</th>
                  <th className="px-4 py-4">Entry Time</th>
                  <th className="px-4 py-4">Exit Time</th>
                  <th>
                    Productive <br /> Working Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4">{item.EmployeeCode}</td>
                    <td>{item.EmployeeName}</td>
                    <td>{splitAndFormatDate(item.LogDate)}</td>
                    <td>{splitAndFormatDateAndTime(item.EntryTime)}</td>
                    <td>
                      {splitAndFormatDateAndTime(item.EntryTime) ==
                      splitAndFormatDateAndTime(item.ExitTime)
                        ? "-"
                        : splitAndFormatDateAndTime(item.ExitTime)}
                    </td>
                    <td>{convertDecimalToTime(item.WorkingHours)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <Button
              variant="primary"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            <div>
              Page {currentPage} of{" "}
              {Math.ceil(filteredData.length / itemsPerPage)}
            </div>
            <Button
              variant="primary"
              style={{marginRight:"18px"}}
              onClick={handleNextPage}
              disabled={
                currentPage === Math.ceil(filteredData.length / itemsPerPage)
              }
            >
              Next
            </Button>
          </div>
          {/* <hr />
          <p className="myheading">
            {" "}
            Absent Employees {`(` + fromDate + " - " + toDate + `)`}
          </p>
          <div
            className="ReportResults"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <Table responsive="sm" id="TableResults">
              <thead className="thead-dark">
                <tr>
                  <th className="px-4">Employee Code</th>
                  <th>Employee Name</th>
                  <th>From - To </th>
                  <th>Days Absent</th>
                  <th>View Dates</th>
                </tr>
              </thead>
              <tbody>
                {employeeAttendance.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4">{item.EmployeeId}</td>
                    <td>{item.EmployeeName}</td>
                    <td>{`${fromDate} - ${toDate}`}</td>
                    <td>
                      {item.absentDays}{" "}
                      {item.absentDays === 0 || item.absentDays === 1
                        ? `Day`
                        : `Days`}{" "}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleViewDates(item)}
                      >
                        View Dates
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div> */}
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Absent Dates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Employee Code: {selectedEmployee?.EmployeeId}</p>
          <p>Employee Name: {selectedEmployee?.EmployeeName}</p>
          <p>Absent Dates : </p>
          {Object.keys(groupedDates).map((month, index) => {
            var notHolidayCount = groupedDates[month].reduce(
              (count, item) =>
                isPublicHoliday(item.date).isHoliday ? count : count + 1,
              0
            );

            return (
              <Card key={index} className="mb-3">
                <Card.Header>{month}</Card.Header>
                <Card.Body>
                  <ul>
                    {groupedDates[month].map((item, idx) => (
                      <li key={idx}>
                        {splitAndFormatDate(item.date)} - {item.day}{" "}
                        {isPublicHoliday(item.date).isHoliday && (
                          <span style={{ color: "red" }}>
                            {"("}
                            {isPublicHoliday(item.date).holidayName}
                            {")"}
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                  <b>Count of Leaves: {notHolidayCount}</b>
                </Card.Body>
              </Card>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const groupAbsentDatesByMonth = (absentDates) => {
  const groupedDates = {};

  absentDates.forEach((item) => {
    const month = new Date(item.date).toLocaleString("default", {
      month: "long",
      year: "numeric",
    });

    if (!groupedDates[month]) {
      groupedDates[month] = [];
    }

    groupedDates[month].push(item);
  });

  return groupedDates;
};

const splitAndFormatDate = (dateTime) => {
  const [date] = dateTime.split("T");
  return date;
};

function splitAndFormatDateAndTime(timestamp) {
  const [date, time] = timestamp.split("T");
  const [hour, minute] = time.split(":").map(Number);
  const ampm = hour >= 12 || hour == 0 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12;
  return `${
    String(formattedHour).length == 1 ? "0" + formattedHour : formattedHour
  }:${String(minute).length == 1 ? "0" + minute : minute} ${ampm}`;
}

const getDaysArray = (start, end) => {
  const arr = [];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export default Reports;
