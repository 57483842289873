import React, { useState } from 'react';
import "../css/Login.css";
import LoginBgImage from "./Assests/Images/LoginPageBg.png";
import MainLogo from "./Assests/Images/MainLogo.png";
import { Form, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import URL from "../Pages/constants";
import axios from 'axios';
import {ActionOpenNotification } from "./notification";
import { NotificationContainer } from 'react-notifications';




function Login() {
    const [updatePasswordType, setUpdatePasswordType] = useState("password");
    const [eyeColor, setEyeColor] = useState(false);
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    const passwordViewHandlerEye = () => {
        setUpdatePasswordType((prevType) => (prevType === "text" ? "password" : "text"));
        setEyeColor((prevEyeColor) => !prevEyeColor);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!email) newErrors.email = " Email Id is required";
        if (!password) newErrors.password = "Password is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };     

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
    
        try {
            const response = await axios.post(URL + '/api/login', { email, password });
            if (response.data.success) {
                var AccessToken = response.data.token;
                localStorage.setItem('accessToken', AccessToken);
                ActionOpenNotification("success", "Successfully", response.data.message);
                window.location.href = '/dailyreport';
            } else {
                ActionOpenNotification("info", "failed", response.message);
            }         
        } catch (error) {
            console.error('Error fetching data:', error.response.data.message);
            ActionOpenNotification("info", "failed", error.response.data.message);
            setErrors({ form: "An error occurred while fetching data" });
        }
    };
    
    



    return (
        <div className="LoginPage d-flex flex-column flex-md-row">
            <div className="LoginBackgroundimage">
                <img src={LoginBgImage} alt="Login Background" />
            </div>
            <Container className='d-flex justify-content-center align-items-center'>
                <div className='LoginContent'>
                    <div className="mainLogo py-5 text-center">
                        <img src={MainLogo} alt="Main Logo" />
                    </div>
                    <div className='px-4'>
                        <div className="LoginText text-center">
                            Login
                        </div>
                        <div className="LoginCredentials px-4 pt-3">
                            <Form className='LoginformData' onSubmit={handleSubmit}>
                                <Form.Group controlId="EmailId">
                                    <div className="EmailId pb-3">
                                        <Form.Control
                                            id='LoginInput'
                                            type="text"
                                            name="email"
                                            placeholder='Enter Email'
                                            value={email}
                                            onChange={(e) => setemail(e.target.value)}
                                        />
                                    </div>
                                    {errors.email && <p className="text-danger text-start">{errors.email}</p>}
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <div className="password position-relative">
                                        <Form.Control
                                            id='LoginInput'
                                            type={updatePasswordType}
                                            name="password"
                                            placeholder='Enter Password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <span
                                            className="password-toggle"
                                            style={{ cursor: "pointer", position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)'}}
                                            onClick={passwordViewHandlerEye}
                                        >
                                            <FontAwesomeIcon icon={eyeColor ? faEyeSlash : faEye} />
                                        </span>
                                    </div>
                                    {errors.password && <p className="text-danger text-start">{errors.password}</p>}
                                </Form.Group>
                                <div className="text-end pb-3 pt-2">
                                    <a style={{color:'#174A91'}} href="/ForgotPassword" className="forgot-password-link">Forgot Password?</a>
                                </div>
                                <Button type="submit" className='btn' id='LoginSubmitBtn' style={{padding: '9px'}}>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Container>
            <NotificationContainer />
        </div>
    );
}

export default Login;
