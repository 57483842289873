import React, { useState } from "react";
import "../css/ForgotPassword.css";
import LoginBgImage from "./Assests/Images/LoginPageBg.png";
import MainLogo from "./Assests/Images/MainLogo.png";
import { Form, Button, Container } from "react-bootstrap";
import URL from "../Pages/constants";
import axios from "axios";
import {ActionOpenNotification } from "./notification";
import { NotificationContainer } from 'react-notifications';

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const response = await axios.post(URL + "/api/forgotpassword", { email });
      if (response.data.success) {
        ActionOpenNotification("success", "Successfully", response.data.message);
        window.location.href = "/login";
      } else {
        ActionOpenNotification("info", "failed", response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error.response.data.message);
      ActionOpenNotification("info", "failed", error.response.data.message);
    }
  };

  return (
    <div className="LoginPage d-flex flex-column flex-md-row">
      <div className="LoginBackgroundimage">
        <img src={LoginBgImage} alt="Login Background" />
      </div>
      <Container className="d-flex justify-content-center align-items-center">
        <div className="LoginContent">
          <div className="mainLogo py-5 text-center">
            <img src={MainLogo} alt="Main Logo" />
          </div>
          <div className="px-4">
            <div className="forgotPasswordText text-center">
              Forgot Password
            </div>
            <div className="LoginCredentials px-4 pt-3">
              <Form className="LoginformData" onSubmit={handleSubmit}>
                <Form.Group controlId="EmpCode">
                  <Form.Control
                    id="LoginInput"
                    type="text"
                    name="email"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <p className="text-danger text-start py-2">
                      {errors.email}
                    </p>
                  )}
                </Form.Group>
                <Button
                  type="submit"
                  className="btn my-3"
                  id="LoginSubmitBtn"
                  style={{ padding: "9px" }}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </Container>
      <NotificationContainer />
    </div>
  );
}

export default ForgotPasswordPage;
