// import { BrowserRouter } from 'react-router-dom';
import './App.css';
import RoutesPage from './Pages/Routes';
 

function App() {
  return (
    <div className="App">
    {/* <BrowserRouter> */}
    <RoutesPage/>
    {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
