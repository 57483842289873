import React from "react";
import "../css/SidebarPage.css";
import { NavLink } from "react-router-dom";

function SidebarPage() {


  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    window.location.href="/login";
  };


  return (
    <div className="leftSideBar py-3">
      <div className="sideBarContant">
        <div className="sidebarTopMenu d-flex flex-column justify-content-between mt-2">
          <nav className="sidebarMenu text-start align-content-center">
            <div id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink
                    to="/dailyReport"
                    className="list-group-item list-group-item-action py-2 ripple"
                    activeClassName="active"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.666" height="22.603" viewBox="0 0 17.666 22.603">
                       <g id="Group_307" data-name="Group 307" transform="translate(-10.004 -3.75)">
                         <path id="Path_1367" data-name="Path 1367" d="M27.419,7.982A.455.455,0,0,0,27.3,7.7L23.525,4.116a.872.872,0,0,0-.134-.087A1.382,1.382,0,0,0,23.246,4H11.751a1.5,1.5,0,0,0-1.5,1.5v19.11a1.5,1.5,0,0,0,1.5,1.5H25.923a1.5,1.5,0,0,0,1.5-1.5s0-16.623,0-16.624ZM23.64,5.314l2.4,2.279h-2.4Zm2.99,19.292a.708.708,0,0,1-.707.707H11.751a.708.708,0,0,1-.707-.707V5.5a.708.708,0,0,1,.707-.707h11.1v3.2a.4.4,0,0,0,.395.395h3.385Z" transform="translate(0 0)" fill="#174a91" stroke="#174a91" stroke-width="0.5"/>
                         <path id="Path_1368" data-name="Path 1368" d="M28.389,14.694a2.375,2.375,0,0,0,0-4.75A2.375,2.375,0,0,0,28.389,14.694Zm0-3.96A1.585,1.585,0,1,1,26.8,12.319a1.585,1.585,0,0,1,1.586-1.585Z" transform="translate(-9.552 -3.598)" fill="#174a91" stroke="#174a91" stroke-width="0.5"/>
                         <path id="Path_1369" data-name="Path 1369" d="M29.937,28.5V26.675A2.48,2.48,0,0,0,27.459,24.2H24.682A2.48,2.48,0,0,0,22.2,26.675V28.5a.4.4,0,0,0,.395.395h6.943a.4.4,0,0,0,.395-.395Zm-5.255-3.512h2.778a1.69,1.69,0,0,1,1.688,1.688V28.1H22.994V26.675a1.69,1.69,0,0,1,1.688-1.688Z" transform="translate(-7.233 -12.226)" fill="#174a91" stroke="#174a91" stroke-width="0.5"/>
                         <path id="Path_1370" data-name="Path 1370" d="M18.325,38.17a.4.4,0,0,0-.558,0l-.555.556-.555-.556a.395.395,0,1,0-.558.558l.555.556-.556.556a.395.395,0,1,0,.558.558l.555-.556.555.556a.395.395,0,0,0,.558-.558l-.556-.556.556-.556A.4.4,0,0,0,18.325,38.17Z" transform="translate(-3.467 -20.613)" fill="#174a91" stroke="#174a91" stroke-width="0.5"/>
                         <path id="Path_1371" data-name="Path 1371" d="M25.169,40.564a.4.4,0,0,0,.395.395h8.229a.395.395,0,1,0,0-.789H25.563A.4.4,0,0,0,25.169,40.564Z" transform="translate(-9.028 -21.893)" fill="#174a91" stroke="#174a91" stroke-width="0.5"/>
                         <path id="Path_1372" data-name="Path 1372" d="M18.325,47.942a.4.4,0,0,0-.558,0l-.555.556-.555-.556a.395.395,0,1,0-.558.558l.556.556-.556.556a.395.395,0,0,0,.558.558l.555-.556.555.556a.395.395,0,0,0,.558-.558l-.556-.556.556-.556a.4.4,0,0,0,0-.558Z" transform="translate(-3.467 -26.528)" fill="#174a91" stroke="#174a91" stroke-width="0.5"/>
                         <path id="Path_1373" data-name="Path 1373" d="M33.792,49.941H25.563a.395.395,0,1,0,0,.789h8.229a.395.395,0,1,0,0-.789Z" transform="translate(-9.027 -27.808)" fill="#174a91" stroke="#174a91" stroke-width="0.5"/>
                       </g>
                    </svg> 
                    <span className="px-2">Daily Report</span>
                  </NavLink>
                </li>
                <br/>
                <li className="nav-item">
                  <NavLink
                    to="/report"
                    className="list-group-item list-group-item-action py-2 ripple"
                    activeClassName="active"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.913" height="21.103" viewBox="0 0 18.913 21.103">
                      <g id="Group_301" data-name="Group 301" transform="translate(-26.57)">
                        <g id="Group_300" data-name="Group 300" transform="translate(26.57)">
                          <g id="Group_299" data-name="Group 299">
                            <path id="Path_1355" data-name="Path 1355" d="M116.6,200.824a.824.824,0,0,0-.824-.824h-8.491a.824.824,0,0,0,0,1.649h8.491A.824.824,0,0,0,116.6,200.824Z" transform="translate(-103.166 -191.757)" fill="#fff"/>
                            <path id="Path_1356" data-name="Path 1356" d="M107.283,280a.824.824,0,0,0,0,1.649h5.157a.824.824,0,1,0,0-1.649Z" transform="translate(-103.166 -268.459)" fill="#fff"/>
                            <path id="Path_1357" data-name="Path 1357" d="M32.624,19.454H29.867a1.651,1.651,0,0,1-1.649-1.649V3.3a1.651,1.651,0,0,1,1.649-1.649H40A1.651,1.651,0,0,1,41.651,3.3v5.07a.824.824,0,0,0,1.649,0V3.3A3.3,3.3,0,0,0,40,0H29.867a3.3,3.3,0,0,0-3.3,3.3V17.806a3.3,3.3,0,0,0,3.3,3.3h2.757a.824.824,0,0,0,0-1.649Z" transform="translate(-26.57)" fill="#fff"/>
                            <path id="Path_1358" data-name="Path 1358" d="M252.707,272.747a2.476,2.476,0,0,0-3.5,0l-4.526,4.516a.824.824,0,0,0-.207.344l-.986,3.245a.824.824,0,0,0,1.009,1.034l3.327-.922a.824.824,0,0,0,.362-.211l4.516-4.508A2.476,2.476,0,0,0,252.707,272.747Zm-5.528,6.685-1.674.464.49-1.613,3.054-3.047,1.166,1.166Zm4.362-4.354-.16.159-1.166-1.166.159-.159a.824.824,0,0,1,1.166,1.165Z" transform="translate(-234.518 -260.812)" fill="#fff"/>
                            <path id="Path_1359" data-name="Path 1359" d="M115.774,120h-8.491a.824.824,0,0,0,0,1.649h8.491a.824.824,0,0,0,0-1.649Z" transform="translate(-103.166 -115.054)" fill="#fff"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span className="px-2">Report</span>
                  </NavLink>
                </li>
                {/* <br/>
                <li className="nav-item">
                  <NavLink
                    to="/summary"
                    className="list-group-item list-group-item-action py-2 ripple"
                    activeClassName="active"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.913" height="21.103" viewBox="0 0 18.913 21.103">
                      <g id="Group_301" data-name="Group 301" transform="translate(-26.57)">
                        <g id="Group_300" data-name="Group 300" transform="translate(26.57)">
                          <g id="Group_299" data-name="Group 299">
                            <path id="Path_1355" data-name="Path 1355" d="M116.6,200.824a.824.824,0,0,0-.824-.824h-8.491a.824.824,0,0,0,0,1.649h8.491A.824.824,0,0,0,116.6,200.824Z" transform="translate(-103.166 -191.757)" fill="#fff"/>
                            <path id="Path_1356" data-name="Path 1356" d="M107.283,280a.824.824,0,0,0,0,1.649h5.157a.824.824,0,1,0,0-1.649Z" transform="translate(-103.166 -268.459)" fill="#fff"/>
                            <path id="Path_1357" data-name="Path 1357" d="M32.624,19.454H29.867a1.651,1.651,0,0,1-1.649-1.649V3.3a1.651,1.651,0,0,1,1.649-1.649H40A1.651,1.651,0,0,1,41.651,3.3v5.07a.824.824,0,0,0,1.649,0V3.3A3.3,3.3,0,0,0,40,0H29.867a3.3,3.3,0,0,0-3.3,3.3V17.806a3.3,3.3,0,0,0,3.3,3.3h2.757a.824.824,0,0,0,0-1.649Z" transform="translate(-26.57)" fill="#fff"/>
                            <path id="Path_1358" data-name="Path 1358" d="M252.707,272.747a2.476,2.476,0,0,0-3.5,0l-4.526,4.516a.824.824,0,0,0-.207.344l-.986,3.245a.824.824,0,0,0,1.009,1.034l3.327-.922a.824.824,0,0,0,.362-.211l4.516-4.508A2.476,2.476,0,0,0,252.707,272.747Zm-5.528,6.685-1.674.464.49-1.613,3.054-3.047,1.166,1.166Zm4.362-4.354-.16.159-1.166-1.166.159-.159a.824.824,0,0,1,1.166,1.165Z" transform="translate(-234.518 -260.812)" fill="#fff"/>
                            <path id="Path_1359" data-name="Path 1359" d="M115.774,120h-8.491a.824.824,0,0,0,0,1.649h8.491a.824.824,0,0,0,0-1.649Z" transform="translate(-103.166 -115.054)" fill="#fff"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span className="px-2">Summary</span>
                  </NavLink>
                </li> */}
                <br/>
                <li className="nav-item">
                  <NavLink
                    to="/masterEmployees"
                    className="list-group-item list-group-item-action py-2 ripple"
                    activeClassName="active"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.913" height="13.87" viewBox="0 0 18.913 13.87">
                      <g id="team" transform="translate(-1 -5)">
                        <path id="Path_1374" data-name="Path 1374" d="M4.152,12.565A2.522,2.522,0,1,0,1.63,10.043,2.524,2.524,0,0,0,4.152,12.565Zm0-3.783a1.261,1.261,0,1,1-1.261,1.261A1.262,1.262,0,0,1,4.152,8.783Zm12.609,3.783a2.522,2.522,0,1,0-2.522-2.522A2.524,2.524,0,0,0,16.761,12.565Zm0-3.783A1.261,1.261,0,1,1,15.5,10.043,1.262,1.262,0,0,1,16.761,8.783Zm1.672,4.264a.631.631,0,0,0-.559-.056,3.139,3.139,0,0,1-2.227,0,.631.631,0,0,0-.559.056,3.173,3.173,0,0,0-.724.632,4.383,4.383,0,0,0-1.306-1.517.63.63,0,0,0-.65-.054,4.411,4.411,0,0,1-3.9,0,.63.63,0,0,0-.65.054,4.383,4.383,0,0,0-1.306,1.517,3.173,3.173,0,0,0-.724-.632.631.631,0,0,0-.559-.056,3.139,3.139,0,0,1-2.227,0,.631.631,0,0,0-.559.056A3.14,3.14,0,0,0,1,15.717v2.522a.63.63,0,0,0,.63.63H19.283a.63.63,0,0,0,.63-.63V15.717a3.14,3.14,0,0,0-1.48-2.671ZM2.261,15.717a1.88,1.88,0,0,1,.663-1.436,4.409,4.409,0,0,0,2.456,0,1.88,1.88,0,0,1,.663,1.436v1.891H2.261Zm5.043,0A3.141,3.141,0,0,1,8.32,13.407a5.609,5.609,0,0,0,1.506.379v1.931a.63.63,0,0,0,1.261,0V13.786a5.609,5.609,0,0,0,1.506-.379,3.141,3.141,0,0,1,1.016,2.311v1.891H7.3Zm11.348,1.891H14.87V15.717a1.88,1.88,0,0,1,.663-1.436,4.409,4.409,0,0,0,2.456,0,1.88,1.88,0,0,1,.663,1.436Zm-8.2-6.3A3.152,3.152,0,1,0,7.3,8.152,3.156,3.156,0,0,0,10.457,11.3Zm0-5.043A1.891,1.891,0,1,1,8.565,8.152,1.893,1.893,0,0,1,10.457,6.261Z" fill="#fff"/>
                      </g>
                    </svg>
                    <span className="px-2">Employees</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
          <div className="sidebarBottomMenu text-start">
            <a className="list-group-item list-group-item-action py-2 ripple"  style={{cursor:'pointer'}}>
             <svg xmlns="http://www.w3.org/2000/svg" width="18.913" height="18.909" viewBox="0 0 18.913 18.909">
                 <g id="logout" transform="translate(-0.024 -0.07)">
                   <path id="Path_1364" data-name="Path 1364" d="M310.665,162.633l-2-2a.868.868,0,1,0-1.228,1.228l1.077,1.077h-4.163a.868.868,0,1,0,0,1.736h4.2l-1.077,1.078a.868.868,0,0,0,1.228,1.228l1.964-1.964a1.683,1.683,0,0,0,0-2.381Z" transform="translate(-292.221 -154.383)" fill="#fff"/>
                   <path id="Path_1365" data-name="Path 1365" d="M13.647,11.4a.9.9,0,0,0-.876.815c-.032.423-.064.565-.1.88a1.946,1.946,0,0,1-.554,1.114,1.978,1.978,0,0,1-1.118.568l-.025,0c-.259.037-.5.069-.749.1.049-.971.08-2.093.088-3.207.014-1.842-.037-3.742-.138-4.969a6.156,6.156,0,0,0-.747-2.443A4.139,4.139,0,0,0,7.912,2.629L7.895,2.62a15.573,15.573,0,0,0-1.622-.8c.305-.01.6-.016,1-.016a19.8,19.8,0,0,1,3.6.269l.024,0a2,2,0,0,1,1.106.587,2.039,2.039,0,0,1,.561,1.125c.1.742.185,1.588.234,2.81a.9.9,0,0,0,.878.847.8.8,0,0,0,.848-.82,26.99,26.99,0,0,0-.274-2.894,4.088,4.088,0,0,0-1.112-2.17A3.943,3.943,0,0,0,11.006.385l-.024,0A18.8,18.8,0,0,0,7.273.07,18.456,18.456,0,0,0,3.61.38l-.024,0A3.957,3.957,0,0,0,1.46,1.562,4.079,4.079,0,0,0,.348,3.732,36.058,36.058,0,0,0,.025,8.469,36.136,36.136,0,0,0,.265,13.2a3.657,3.657,0,0,0,.5,1.386c.072.148.15.3.233.437a4.668,4.668,0,0,0,1.555,1.667l.017.01a19.648,19.648,0,0,0,2.683,1.348,13.525,13.525,0,0,0,2.628.887l.017,0a1.471,1.471,0,0,0,1.465-.486A3.266,3.266,0,0,0,10.1,16.62c.351-.042.665-.089,1-.142l.024,0a3.928,3.928,0,0,0,2.154-1.136A3.841,3.841,0,0,0,14.376,13.2c.047-.339.083-.506.119-.941a.8.8,0,0,0-.848-.853Z" transform="translate(0)" fill="#fff"/>
                 </g>
             </svg>
             <span className="px-2" style={{color:'white'}} onClick={handleLogout}>Logout</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarPage;
