import React, { useEffect, useState } from "react";
import HeaderPage from "./HeaderPage";
import SidebarPage from "./SidebarPage";
import {Form, Button, Table } from "react-bootstrap";
import "../css/Summary.css";


const dataList = [
    {
        "EmpName" :"John Doe",
        "EmpCode":"JD1234",
        "days":"10",
        "fromDate": "2024-05-01",
        "toDate": "2024-05-10"
    },
    {
        "EmpName" :"Jane Smith",
        "EmpCode":"JS5678",
        "days":"15",
        "fromDate": "2024-05-11",
        "toDate": "2024-05-25"
    },
    {
        "EmpName" :"Michael Brown",
        "EmpCode":"MB9876",
        "days":"20",
        "fromDate": "2024-05-26",
        "toDate": "2024-06-14"
    },
    {
        "EmpName" :"Emily Johnson",
        "EmpCode":"EJ3456",
        "days":"25",
        "fromDate": "2024-06-15",
        "toDate": "2024-07-09"
    },
    {
        "EmpName" :"Daniel Wilson",
        "EmpCode":"DW2345",
        "days":"30",
        "fromDate": "2024-07-10",
        "toDate": "2024-08-08"
    },
    {
        "EmpName" :"Sophia Martinez",
        "EmpCode":"SM6543",
        "days":"35",
        "fromDate": "2024-08-09",
        "toDate": "2024-09-13"
    },
    {
        "EmpName" :"William Garcia",
        "EmpCode":"WG7890",
        "days":"40",
        "fromDate": "2024-09-14",
        "toDate": "2024-10-24"
    },
    {
        "EmpName" :"Olivia Thomas",
        "EmpCode":"OT4321",
        "days":"45",
        "fromDate": "2024-10-25",
        "toDate": "2024-12-08"
    },
    {
        "EmpName" :"Liam Rodriguez",
        "EmpCode":"LR5678",
        "days":"50",
        "fromDate": "2024-12-09",
        "toDate": "2025-01-28"
    },
    {
        "EmpName" :"Ava Jackson",
        "EmpCode":"AJ9876",
        "days":"55",
        "fromDate": "2025-01-29",
        "toDate": "2025-03-25"
    },
    {
        "EmpName" :"John Doe",
        "EmpCode":"JD1234",
        "days":"10",
        "fromDate": "2024-05-01",
        "toDate": "2024-05-10"
    },
    {
        "EmpName" :"Jane Smith",
        "EmpCode":"JS5678",
        "days":"15",
        "fromDate": "2024-05-11",
        "toDate": "2024-05-25"
    },
    {
        "EmpName" :"Michael Brown",
        "EmpCode":"MB9876",
        "days":"20",
        "fromDate": "2024-05-26",
        "toDate": "2024-06-14"
    },
    {
        "EmpName" :"Emily Johnson",
        "EmpCode":"EJ3456",
        "days":"25",
        "fromDate": "2024-06-15",
        "toDate": "2024-07-09"
    },
    {
        "EmpName" :"Daniel Wilson",
        "EmpCode":"DW2345",
        "days":"30",
        "fromDate": "2024-07-10",
        "toDate": "2024-08-08"
    },
    {
        "EmpName" :"Sophia Martinez",
        "EmpCode":"SM6543",
        "days":"35",
        "fromDate": "2024-08-09",
        "toDate": "2024-09-13"
    },
    {
        "EmpName" :"William Garcia",
        "EmpCode":"WG7890",
        "days":"40",
        "fromDate": "2024-09-14",
        "toDate": "2024-10-24"
    },
    {
        "EmpName" :"Olivia Thomas",
        "EmpCode":"OT4321",
        "days":"45",
        "fromDate": "2024-10-25",
        "toDate": "2024-12-08"
    },
    {
        "EmpName" :"Liam Rodriguez",
        "EmpCode":"LR5678",
        "days":"50",
        "fromDate": "2024-12-09",
        "toDate": "2025-01-28"
    },
    {
        "EmpName" :"Ava Jackson",
        "EmpCode":"AJ9876",
        "days":"55",
        "fromDate": "2025-01-29",
        "toDate": "2025-03-25"
    }
];

function Summury() {
const regex = /^[a-zA-Z0-9]*$/;

const [empname, setempname] = useState("");
const [empcode, setempcode] = useState("");
const [fromdate, setfromdate] = useState("");
const [todate,  settodate] = useState("");
const [filteredData, setFilteredData] = useState([]);
const [currentPage, setCurrentPage] = useState(1);

const itemsPerPage = 8;


useEffect(()=>{
  setFilteredData(dataList);
},[]);

const handleFilterSubmit = (e) => {
    e.preventDefault();
    let filteredResult = dataList;

    if (empname.trim() !== '') {
      filteredResult = filteredResult.filter((item) =>
        item.EmpName.toLowerCase().includes(empname.toLowerCase())
      );
    }

    if (empcode.trim() !== '') {
      filteredResult = filteredResult.filter(
        (item) => item.EmpCode.toLowerCase() === empcode.toLowerCase()
      );
    }

    if (fromdate.trim() !== '') {
      filteredResult = filteredResult.filter(
        (item) => item.LoginTime.split(' ')[0] === fromdate
      );
    }

    if (todate.trim() !== '') {
      filteredResult = filteredResult.filter(
        (item) => item.LogoutTime.split(' ')[0] === todate
      );
    }

    setFilteredData(filteredResult);
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };


  return (
    <div>
      <div className="Header">
        <HeaderPage />
      </div>
      <div className="mainBody d-flex">
          <div className="leftSidebar"  style={{width:'20%'}}>
            <SidebarPage />
          </div>
          <div className="DailyReport mt-4 ms-3" style={{width:'80%', padding: '16px', position: 'relative', top: '66px'}}>
            <div className="reportFilterbar">
              <Form className="FilterData" onSubmit={handleFilterSubmit}>
                <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between" style={{width: '83%'}}>

                  <Form.Group controlId="EmployeeName" id="formGroupEmployeeName">
                    <Form.Label className="d-flex text-start" id="reportFormLabel"> Employee Name </Form.Label>
                    <div className="EmployeeName d-flex pb-3">
                      <Form.Control id="EmployeeName"  type="text"  name="empname" placeholder="Enter Name" value={empname}  onChange={(e)=> setempname(e.target.value)} />
                    </div>
                  </Form.Group>

                  <Form.Group controlId="EmployeeCode" id="formGroupEmployeeCode">
                    <Form.Label className="d-flex  text-start" id="reportFormLabel"> Employee Code </Form.Label>
                    <div className="EmployeeCode d-flex pb-3">
                      <Form.Control id="EmployeeCode" type="text" name="empcode" placeholder="Enter Code"  maxLength={7} value={empcode}  onChange={(e) => { const inputValue = e.target.value; const isValid = regex.test(inputValue); setempcode(isValid ? inputValue : ''); }} />
                    </div>
                  </Form.Group>


                  <Form.Group controlId="Form" id="formGroupForm">
                    <Form.Label className="d-flex  text-start" id="reportFormLabel"> From </Form.Label>
                    <div className="Form d-flex pb-3" style={{width: '210px'}}>
                      <Form.Control id="FormInput" type="date"  name="fromdate" placeholder="Choose Date" onChange={(e)=> setfromdate(e.target.value)} />
                    </div>
                  </Form.Group>

                  <Form.Group controlId="To" id="formGroupTo">
                    <Form.Label className="d-flex text-start" id="reportFormLabel"> To </Form.Label>
                    <div className="To d-flex pb-3" style={{width: '210px'}}>
                      <Form.Control id="ToInput" type="date" name="todate" placeholder="Choose Date" onChange={(e)=> settodate(e.target.value)} />
                    </div>
                  </Form.Group>

                </div>
                <div className=" ">
                  <button className="btn" id="ReportFilterBtn">
                    Submit
                  </button>
                </div>
                </div>
              </Form>
            </div>
            <hr />
            <div className="ReportResults">
              <Table  responsive="sm" id='TableResults' >
                <thead  className="thead-dark">
                    <tr>
                    <th>Employee Name</th>
                    <th>Employee Code</th>
                    <th>Day of Present</th>
                    </tr>
                </thead>      
                {paginatedData.map((item)=>(
                     <tbody className=''>  
                    <tr style={{borderBottom: '1px solid #DADADA'}}>
                    <td>{item.EmpName}</td>
                    <td>{item.EmpCode}</td>
                    <td>{item.days}</td>                    
                    </tr>
                    </tbody>
                ))}             
                </Table>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button variant="primary" onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </Button>
              <div>
                Page {currentPage} of {totalPages}
              </div>
              <Button variant="primary" onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </Button>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Summury;
