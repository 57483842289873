import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/HeaderPage.css";
import HeaderMainLogo from "./Assests/Images/HomePageLogo.png";

function HeaderPage() {
  const [userData, setUserData] = useState({
    name: 'HR Department',
    email: 'hr@vmokshagroup.com'
  });

  return (
    <div className='HeaderPage d-flex justify-content-between px-5 py-2 text-center align-items-center'>
      <div className="DailyReportLogo">
        <img src={HeaderMainLogo} alt="Home Page Logo" />
      </div>
      <div className='EmpCode d-flex'>
        <div className='headlogoicon'>HR</div>
        <div className='px-2' style={{ textAlign: 'start',lineHeight: '28px'}}>
          <span className='logocontent'>{userData.name}</span><br />
          <span className='logocontent'>{userData.email}</span>
        </div>
      </div>
    </div>
  );
}

export default HeaderPage;
