import React, { useEffect } from 'react'
import MainLogo from "./Assests/Images/MainLogo.png"
import BackgroundImg from "./Assests/Images/HomePageBackgorup.png"
import "../css/LoginInitialPage.css"

export default function LoginInitialPage() {

  useEffect(()=>{
    setTimeout(()=>{
       window.location.href="/login";
    },3000)
  },[])

  return (
    <div className='d-flex'> 
            <div  id='LoginInitialPage'>
                <img src={BackgroundImg} alt="" style={{width:"100%",height:"100%"}} />
            </div>

            <div className="LoginInitialPage" >
                <img src={MainLogo} alt="MainLogo" />
            </div>
    </div>
  )
}
